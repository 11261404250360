<template>
	<div class="d-flex flex-grow-1  h-100 align-items-center justify-content-center p-5">
		<div class="p-4" style="max-width: 550px; z-index: 99;">
			<div class="mb-5">
				<img class="logo" v-lazy="logo" style="height: 2rem;" alt="Amino Logo" />
			</div>
			<div class="display-1 mb-4">
				We'll be online shortly.
			</div>
			<div class="lead text-muted my-4">
				We have submitted a shiny new version of Amino to the Chrome Web Store. We'll launch the website as soon as the extension has been published.
			</div>
			<div class="lead text-muted">
				In the meantime, you may follow <a href="https://twitter.com/aminoeditor">@aminoeditor</a> on Twitter for updates.
			</div>
		</div>
		<div class="bg-split cascade-1"></div>
	</div>
</template>

<script>
const logo = require('../assets/images/icon.svg');
export default {
	name: 'ComingSoon',
	data () {
		return {
			logo
		}
	}
}
</script>

<style lang="scss" scoped>
.display-1 {
    font-size: 3.5rem;
}
a {
	color: inherit;
	text-decoration: underline;
}
</style>
